
.loader {
  width: 100%;
  height: 200px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 5.5s infinite;
}
.mobileLoader {
  width: 100%;
  height: 100px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 5.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.imageLoaded {
  opacity: 1; /* Fully visible when image is loaded */
}


.webCarouselCard{
  box-shadow: 0px 2px 4px 0px #00000026;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 100%;
  /* min-width: 283px; */
  min-height: 275px;
}
.webCarouselCard > img {
  width: 100%;
}


 

  .nameTitle{
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin: 10px 0px 10px 0px;
  }
  .nameDetails{
    color: #9C9C9C;
    font-size: 16px;
    font-weight: 400px;
    margin: 0;
    padding: 0px 10px 0px 10px;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    min-height: 3rem;
  line-height: 1rem;
  }
  .container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0px 80px 0px;
    width: 100%;
    
  }
  .subContainer{
    background: #fff;
    /* box-shadow: 0px 1px 7px 0px #00000040; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  .teamDesc{
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    /* box-shadow: 1px 1px 5px #484848; */
  }
  .teamDesc > svg {
    width: 100%;
    height: 100%;
  }

  .ouTeam{
    
font-size: 33px;
font-weight: 700;
line-height: 46px;
letter-spacing: 0.20000000298023224px;
text-align: left;

  }
  .ourTeamDescription{

font-size: 15px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0.20000000298023224px;
text-align: left;

  }



  .teamCarousel{
    width: 100%;
    
  }

  .socialLogo{
    cursor: pointer;
    text-align: left;
    
  }
  .teamMobileCarousel{
    display: none;
  }

  @media screen and (min-width:993px ) and (max-width: 1199px) {
    .subContainer{
      padding: 10px;
      width: 90%;
    }
    .teamDesc >svg {
      width: 100%;
    }
    .webCarouselCard{
      min-height: 300px;
    }
    .nameTitle{
      
      font-size: 20px;
      font-weight: 600px;
    }
    .nameDetails{
      font-size: 15px;
      min-height: 4rem;
    }
  }

  @media screen and (min-width:769px ) and (max-width: 992px) {
    .subContainer{
        flex-direction: column;

    }
    .teamDesc{
        width: 100%;
        margin-bottom: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .teamCarousel{
        width: 100%;
    }
    .teamDesc > svg {
      display: none;
      width: 100%;
    }
    .nameTitle{
      color: #000;
      font-size: 15px;
      font-weight: 500px;
    }
    .nameDetails{
      font-size: 10px;
      min-height: 4rem;
      /* max-height: 3rem; */
    line-height: 1rem;
    }
    .webCarouselCard{
      min-height: 250px;
    }
  }

  @media screen and (max-width:768px) {
    .container{
      flex-direction: column;
    }
    .subContainer{
      width: 95%;
      flex-direction: column;

    }
    .teamDesc{
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .teamDesc > svg {
      display: none;
      width: 100%;
    }
    .teamCarousel{
      display: none;
    }
    .teamMobileCarousel{
      display: flex;
      width: 100%
    }


    .nameTitle{
      font-size: 16px;
    }
    .nameDetails{
      font-size: 13px;
      min-height: 5rem;
      /* max-height: 3rem; */
    line-height: 1rem;
    }
    .webCarouselCard{
      min-height: 300px;
    }
    


  }